import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';

import css from './ListingPage.module.css';

const SectionDetailsMaybe = props => {
  console.log('SectionDetailsMaybe props:', props);
  const { publicData, metadata = {}, listingConfig, intl } = props;
  const { listingFields } = listingConfig || {};

  if (!publicData || !listingConfig) {
    return null;
  }

  // String to be added between the listing field values
  const separatorString = ' ';

  const pickListingFields = (accumulatedValues, config) => {
    const { key, 
            schemaType, 
            enumOptions, 
            includeForListingTypes, 
            showConfig = {} 
          } = config;
    const listingType = publicData.listingType;
    const isTargetListingType =
      includeForListingTypes == null || includeForListingTypes.includes(listingType);

    // Array of specific field keys you want to include in the title
    const fieldsForTitle = ['clinic_type', 'space_type'];

    // Check if the current field key is included in the fieldsForTitle array
    const includeInTitle = fieldsForTitle.includes(key);

    const { isDetail } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    let value = publicDataValue || metadataValue;

    if (isDetail && 
        isTargetListingType && 
        typeof value !== 'undefined' &&
        includeInTitle) {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const optionConfig = findSelectedOption(value);

      const formattedValue =
        schemaType === 'enum'
          ? optionConfig?.label
          : schemaType === 'boolean'
          ? intl.formatMessage({ id: value ? 'SearchPage.detailYes' : 'SearchPage.detailNo' })
          : schemaType === 'long'
          ? String(value)
          : '';

      // Remove the last word from the value (assumes the value is a string)
      const valueWords = formattedValue.split(' ');
      valueWords.pop(); // Remove the last word
      value = valueWords.join(' ');

      // Concatenate the values for fields included in the title with the separatorString
      return accumulatedValues.concat(value);
    }
    return accumulatedValues;
  };

 // Apply the pickListingFields function to get the accumulated values
 const titleValues = listingFields.reduce(pickListingFields, []);

 // Concatenate the accumulated values with the separatorString
 const title = titleValues.join(separatorString);

 const address = publicData && publicData.location ? publicData.location.address : '';
 const address2 = address.split(",");
 const city = address2[2];
 const town = address2[1];

 return title ? (
   <div className={css.sectionDetails}>
     <Heading as="h3">
       {title} Space in {town}
     </Heading>
   </div>
 ) : null;
};

export default SectionDetailsMaybe;